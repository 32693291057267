import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu61100: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteNeutral3, whiteBg2 },
      colorTypes: { text5, text2, white, primary, accent },
    },
    margins,
  } = themeVars;

  return {
    SportsMenuDesktop: {
      sport: {
        ...whiteBg2,
      },
      region: {
        ...whiteBg2,
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: white,
      },
      title: {
        color: white,
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: text2,
        borderRight: 'none',
        padding: [0, margins.md],
      },
      sportLink: {
        padding: 0,
        transition: '0.2s ease-in',
        color: text5,
        '&.active, &:hover': {
          color: accent,
        },
      },
    },
    SportsMenuMobile: {
      region: {
        '&.active': {
          ...whiteNeutral3,
          '&:hover': whiteNeutral3,
        },
        '&:hover': whiteNeutral3,
      },
    },
    SportsMenuMobileRegions: {
      region: {
        background: '#4283E7',
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
    },
  };
};
