// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection61100: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background2, white },
    },
    margins,
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        background: background2,
        border: 'none',
        boxShadow: 'none',
        maxWidth: 170,
        borderRadius: 0,
      },
      logoImg: {
        marginBottom: 10,
        opacity: '.9',
      },
    },
    OPMPageComponent: {
      heading: {
        paddingLeft: 10,
      },
    },
    OPMForms: {
      fieldWrapper: {
        maxWidth: '250px',
      },
      description: {
        '& a': {
          color: white,
        },
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: background2,
        paddingTop: margins.md,
      },
    },
  };
};
