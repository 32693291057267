// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets61100: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorCombinations: { bg2Neutral1 },
      colorTypes: { background2, neutral1 },
    },
  } = themeVars;
  return {
    ConfirmationBox: {
      confirmation: {
        background: neutral1,
      },
      text: {
        color: background2,
      },
      buttonsContainer: {
        background: neutral1,
      },
    },
    CashOut: {
      success: bg2Neutral1,
    },
    MyBets: {
      content: {
        background: neutral1,
      },
    },
  };
};
