// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61100: JSSThemeDefaultHeaderType = themeVars => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: primary,
      },
      authContentWrap: {
        justifyContent: 'flex-start',
      },
      authWrapper: {
        padding: 0,
      },
    },
  };
};
