// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61100: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: '#2C4873',
      color: white,
    },
    licenseLink: {
      paddingRight: 0,
    },
    menuWrapper: {
      paddingBottom: 0,
    },
  };
};
