// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61100: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { text5 },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        '@media (pointer: coarse)': {
          background: text5,
        },
      },
      header: {
        fontSize: fontSizes.xxl,
        '@media (max-width: 1000px)': {
          height: 'auto',
        },
      },
    },
  };
};
