// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino61100: JSSThemeDefaultCasinoType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: {
        white,
        background2,
        neutral4,
        text4,
        primary,
        accent,
        black,
      },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: background2,
      },
      menuItem: {
        color: white,
        borderRight: 'none',
        '&.active, &:hover': {
          background: primary,
          color: white,
        },
      },
      iconChevron: {
        marginLeft: margins.xs,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    CasinoGameLink: {
      rootReal: {
        color: white,
        background: accent,
        '&.active, &:hover': {
          color: white,
          background: accent,
          opacity: 0.9,
        },
      },

      rootFun: {
        background: white,
        color: black,
        '&.active, &:hover': {
          background: white,
          color: black,
          opacity: 0.9,
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
        color: black,
      },
      inputSearch: {
        height: 32,
        background: white,
        borderBottom: 'none',
        marginRight: margins.md,
        color: background2,
        '&::placeholder': {
          color: text4,
        },
      },
      iconSearch: {
        color: text4,
        right: 5,
      },
      searchResult: {
        padding: [0, margins.md],
        background: neutral4,
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: neutral4,
      },
      providersFilters: {
        '&.active': {
          color: white,
          background: primary,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: white,
            background: primary,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
    },
  };
};
