// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61100: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorTypes: { background1, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      iconContainer: {
        color: white,
      },
      menuContainer: {
        background: background1,
      },
    },
  };
};
