// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher61100: JSSThemeDefaultLanguageSwitcherType = themeVars => {
  const {
    colors: {
      colorTypes: { text4, white, text2, text5 },
    },
  } = themeVars;
  return {
    LanguageSelectComponentMobile: {
      select: {
        color: text2,
      },
      languageDescription: {
        color: text2,
      },
      languageChevron: {
        color: text2,
      },
    },
    LanguageSelectComponent: {
      languageSelectWrap: {
        color: text2,
      },
      languageSelect: {
        '&:before': {
          background: white,
        },
      },
      languageItem: {
        color: text5,
        background: white,
        '&:first-child': {
          borderRadius: '5px 5px 0 0',
        },
        '&:last-child': {
          borderRadius: '0 0 5px 5px',
        },
        '&:hover': {
          color: text4,
          background: text2,
        },
      },
    },
  };
};
