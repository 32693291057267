// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61100: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { bg1White },
      colorTypes: { background1, text4, white },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        border: `1px solid #c6c6c6`,
        ...bg1White,
        '&::placeholder': {
          color: '#c6c6c6',
        },
        '&:-webkit-autofill': {
          //  '-webkit-box-shadow': `0 0 0 30px ${border2} inset !important`,
          '-webkit-text-fill-color': `${background1} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        borderRadius: borderRadius.md,
        border: 'none',
        background: white,
        '&::placeholder': {
          color: text4,
        },
      },
      iconShowPassword: {
        color: background1,
        opacity: 0.7,
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: background1,
        opacity: 0.5,
      },
    },
  };
};
