// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61100: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '-27px',
    left: '6%',
    width: '90%',
    borderBottom: `2px solid #fbb374`,
  };
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: white,
        '&:hover, &:focus': {
          color: '#fbb374',
          '&:after': menuItemAfterElement,
        },
      },
      menuItemActive: {
        color: '#fbb374',
        '&:after': menuItemAfterElement,
      },
    },
  };
};
