// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61100: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { text5, neutral1, white },
    },
  } = themeVars;

  return {
    LoginFormDesktop: {
      popUpWrapper: {
        color: text5,
        background: '#f6f6f6',
        boxShadow: 'none',
        '&:before': {
          background: '#f6f6f6',
        },
      },
      inputWrapper: {
        borderRadius: '2px',
        InputField: {
          border: '1px solid #9c9c9c',
        },
      },
    },
    PasswordResetWithEmail: {
      contactUsWrap: {
        '&:hover': {
          opacity: 1,
        },
      },
      contactUsLink: {
        color: '#575757',
        fontWeight: 'bold',
        '&:hover': {
          color: neutral1,
        },
      },
      passwordResetDebounce: {
        color: '#575757',
        '@media (pointer: coarse)': {
          color: white,
        },
      },
    },
  };
};
