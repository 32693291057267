// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, applyOnlyMobile } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61100';
import { JSSThemeMyBets61100 } from './JSSThemeMyBets61100';
import { JSSThemeHeaderMobile61100 } from './JSSThemeHeaderMobile61100';
import { JSSThemeSportsMenu61100 } from './JSSThemeSportsMenu61100';
import { JSSThemeBannerItems61100 } from './JSSThemeBannerItems61100';
import { JSSThemeForm61100 } from './JSSThemeForm61100';
import { JSSThemeBonuses61100 } from './JSSThemeBonuses61100';
import { JSSThemePromotions61100 } from './JSSThemePromotions61100';
import { JSSThemeSitemap61100 } from './JSSThemeSitemap61100';
import { JSSThemeCoupons61100 } from './JSSThemeCoupons61100';
import { JSSThemeSearch61100 } from './JSSThemeSearch61100';
import { JSSThemeHeaderMenu61100 } from './JSSThemeHeaderMenu61100';
import { JSSThemeOutcomes61100 } from './JSSThemeOutcomes61100';
import { JSSThemeBalance61100 } from './JSSThemeBalance61100';
import { JSSThemeHeader61100 } from './JSSThemeHeader61100';
import { JSSThemeAuthForm61100 } from './JSSThemeAuthForm61100';
import { JSSThemeAccountSection61100 } from './JSSThemeAccountSection61100';
import { JSSThemeLanguageSwitcher61100 } from './JSSThemeLanguageSwitcher61100';
import { JSSTheme61100Resets } from './JSSTheme61100Resets';
import { JSSThemeCasino61100 } from './JSSThemeCasino61100';
import { JSSThemePopularLeagues61100 } from './JSSThemePopularLeagues61100';
import { JSSThemeLive61100 } from './JSSThemeLive61100';
import { JSSThemeBetgenius61100 } from './JSSThemeBetgenius61100';
import { JSSThemeAffiliates61100 } from './JSSThemeAffiliates61100';
import { JSSThemeFooterAll61100 } from './JSSThemeFooterAll61100';
import { JSSThemeDropdownMenu61100 } from './JSSThemeDropdownMenu61100';

export const colorTypes61100: ColorTypes = {
  ...colorTypes,
  primary: '#4283E7',
  text1: 'rgba(245, 245, 245, 0.6)',
  text2: '#000',
  text3: '#a94442',
  text4: '#9c9c9c',
  text5: '#4E5A6C',
  background1: '#4E5A6C',
  background2: '#4E5A6C',
  background3: '#40701E',
  background4: '#e7e7e7',
  background5: '#FD9840',
  background6: '#FD9840', // new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#FD9840',
  success: '#21a22d',
  error: '#f14d4d',
  error2: '#d82e2e',
  error3: '#d82e2e',
  warning: '#ffa500',
  inform: '#4283E7',
  neutral1: '#ccc',
  neutral2: '#eabd11',
  neutral3: '#FD9840',
  neutral4: '#F1F1F1',
  border1: '#eabb5c',
  border2: '#404040',
};

const pick = ({
  text2Bg2,
  Bg2text2,
  text2Bg1,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
}) => ({
  text2Bg2,
  Bg2text2,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text2Bg1,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
});

export const colorCombinations61100 = getColorCombinations(
  colorTypes61100,
  pick
);

export const JSSThemeVariables61100: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorCombinations: colorCombinations61100,
    colorTypes: colorTypes61100,
  },
};

export const JSSTheme61100: JSSThemeShapeType = themeVars => {
  const {
    colors: {
      colorCombinations: {
        bg1Neutral1,
        neutral3Text2,

        text2Accent,
        text1,
      },
      colorTypes: {
        background1,
        neutral1,
        neutral4,
        text2,
        text5,
        black,
        white,
        neutral3,
        error,
        primary,
      },
    },
    fontSizes,
    margins,
    fonts,
  } = themeVars;
  return {
    mybets: JSSThemeMyBets61100(themeVars),
    outcomes: JSSThemeOutcomes61100(themeVars),
    betslip: betslip(themeVars),
    headerMobile: JSSThemeHeaderMobile61100(themeVars),
    promotions: JSSThemePromotions61100(themeVars),
    sitemap: JSSThemeSitemap61100(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher61100(themeVars),
    NoBetsMessage: {
      root: { ...bg1Neutral1, fontWeight: 'normal' },
    },
    Heading: {
      common: {
        fontSize: '18px',
        color: text1,
        fontFamily: fonts.agora,
        padding: '5px 15px 5px',
      },
    },
    Button: {
      root: {
        padding: ['1px', margins.xm, '0'],
        lineHeight: '31px',
      },
      linkAccent: {
        color: text5,
        '&:hover': {
          color: text5,
          textDecoration: 'none',
        },
      },
      primary: {
        background: primary,
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            background: primary,
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#60a349',
            color: text2,
          },
        },
      },
      default: {
        background: white,
        color: black,
        '&:hover, &:active': {
          background: white,
          color: black,
          opacity: 0.9,
        },
      },
      secondary: {
        background: neutral3,
        color: white,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: neutral3,
            color: white,
            opacity: 0.9,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        },
      },
      accent: {
        background: primary,
        color: white,
        '&:hover': {
          background: primary,
          color: white,
        },
        '&:disabled': {
          background: error,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: error,
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        fontWeight: 'normal',
        boxShadow: 'none',
        background: '#989EA8',
        color: white,
        padding: '1px 10px 0',
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
    banners: JSSThemeBannerItems61100(themeVars),
    sportsMenu: JSSThemeSportsMenu61100(themeVars),
    form: JSSThemeForm61100(themeVars),
    bonuses: JSSThemeBonuses61100(themeVars),
    coupons: JSSThemeCoupons61100(themeVars),
    header: JSSThemeHeader61100(themeVars),
    headerMenu: JSSThemeHeaderMenu61100(themeVars),
    accountSection: JSSThemeAccountSection61100(themeVars),
    popularLeagues: JSSThemePopularLeagues61100(themeVars),
    affiliates: JSSThemeAffiliates61100(themeVars),
    footerAll: JSSThemeFooterAll61100(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61100(themeVars),

    search: JSSThemeSearch61100(themeVars),
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: text5,
        borderTop: `1px solid ${background1}`,
      },
      stakePotential: {
        color: text5,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${background1}`,
      },
      panel: {
        background: neutral1,
        padding: 0,
      },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      h2: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        background: neutral4,
      },
      dateTitle: {
        background: 'transparent',
      },
      dateEvent: {
        borderBottom: `1px solid ${text5}`,
        background: 'transparent',
        '&:hover': {
          cursor: 'pointer',
          color: white, // $live-menu-event-color-hover;
          background: primary, // $live-menu-event-background-color-hover
        },
      },
    },
    balance: JSSThemeBalance61100(themeVars),
    authForm: JSSThemeAuthForm61100(themeVars),
    casino: JSSThemeCasino61100(themeVars),
    live: JSSThemeLive61100(themeVars),
    betgenius: JSSThemeBetgenius61100(themeVars),
    resets: JSSTheme61100Resets(themeVars),
  };
};

export const getJSSThemePatterns61100: GetJSSThemePatternsType = themeVars => {
  const {
    colors: {
      colorCombinations: {
        text2Accent,
        text2Primary,
        neutral3Text2,
        text2Error2,
      },
      colorTypes: { text2, white, neutral3, error },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...text2Primary,
        ...applyOnlyDesktop({
          '&:hover': {
            background: '#60a349',
            color: text2,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#60a349',
            color: text2,
          },
        },
      },
      secondary: {
        ...neutral3Text2,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: white,
            color: neutral3,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        },
      },
      accent: {
        ...text2Error2,
        '&:hover': {
          ...text2Error2,
        },
        '&:disabled': {
          background: error,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: error,
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
