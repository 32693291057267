// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons61100: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { text4, accent, black },
    },
  } = themeVars;
  return {
    SportTabs: {
      tab: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
        },
      },
      tabs: {
        padding: [0, margins.xs],
      },
    },
    MobileWidgetCouponView: {
      sportHeader: {
        color: black,
      },
      sportInnerLink: {
        color: black,
      },
    },
    WidgetSportMobile: {
      league: {
        color: '#fff',
      },
    },
    SuspendedBlock: {
      suspendedBlock: {
        color: '#fff',
      },
    },
    Market: {
      root: {
        padding: [0, 5],
        borderTop: 'none',
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: accent,
        fontWeight: 'bold',
        '&:hover': {
          background: text4,
        },
      },
    },
    WidgetSport: {
      sport: {
        padding: [0, margins.lg],
      },
      event: {
        background: '#fff',
      },
    },
    Betbuilder: {
      toggler: {
        margin: [margins.md, 0, 0],
      },
      exampleContainer: {
        margin: 0,
      },
    },
    PrematchEvent: {
      eventDescription: {
        width: '100%',
      },
    },
  };
};
