// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeSearch61100 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { neutral1, neutral4, text4 },
    },
  } = themeVars;

  return {
    SearchBar: {
      inputContainer: {
        color: neutral1,
      },
      resultContainer: {
        maxHeight: 'calc(100vh - 93px - 37px)',
      },
    },
    SportsbookSearchResult: {
      title: {
        color: '#fff',
      },
      event: {
        background: '#fff',
      },
      marketCount: {
        background: neutral4,
        fontWeight: 'bold',
        color: '#fff',
        '&:hover': {
          color: '#fff',
          background: text4,
        },
      },
    },
  };
};
