// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61100: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text5, primary },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      selectorWrap: {
        color: white,
      },
      item: {
        color: text5,
        background: white,
        '&:hover,&.active': { color: white, background: primary },
      },
      list: {
        '&:before': {
          background: white,
        },
      },
    },
  };
};

