// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems61100: JSSThemeDefaultBannerItemsType = themeVars => {
  const {
    fontSizes,
    margins,
    fonts,
    colors: {
      colorTypes: { primary, white, background2, background1 },
    },
  } = themeVars;
  return {
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        maxHeight: 271,
        marginRight: 0,
      },
      contentWrapper: {
        backgroundColor: primary,
        padding: 0,
        width: 'auto',
      },
      imgContainer: {
        height: '135px',
        backgroundColor: primary,
        backgroundSize: 'cover',
        backgroundPosition: '30% center',
      },
    },
    SideBanner: {
      containerWithContent: {
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        backgroundSize: '100%',
        backgroundPosition: 'center 85px',
        '&::before': {
          width: '100%',
          background: '#4E5A6C',
          height: 85,
          top: '0',
          display: 'block',
          content: '""',
          position: 'absolute',
        },
      },
    },
    BannerContent: {
      bannerContentRoot: {
        height: '100%',
      },
      bannerContentSportsbookSide: {
        padding: [margins.md, margins.md, margins.xm],
      },
      bannerContentCasinoCarousel: {
        justifyContent: 'space-between',
        padding: [margins.xl, margins.lg, margins.lg],
      },
      headerCasinoCarousel: {
        fontSize: '95px',
        lineHeight: '80px',
        fontWeight: 900,
      },
      descriptionCasinoCarousel: {
        fontSize: '40px',
        lineHeight: '40px',
        fontWeight: 900,
        padding: [margins.xm, 0, 0, 0],
      },
      textWrapperPromo: {
        background: primary,
      },
      headerPromo: {
        padding: [margins.md, 0, 0],
        textShadow: 'none',
        fontWeight: 'bold',
        fontSize: fontSizes.xxxl,
      },
      descriptionPromo: {
        fontSize: fontSizes.md,
        fontWeight: 'normal',
      },
      textWrapperFill: {
        padding: 0,
        zIndex: 1,
      },
      headerSportsbookSide: {
        textShadow: 'none',
        fontSize: 35,
        fontWeight: '900',
        lineHeight: 1.2,
      },
      descriptionSportsbookSide: {
        padding: '0 0 5px',
        textShadow: 'none',
        fontSize: fontSizes.lg,
        maxWidth: '100%',
        fontWeight: 900,
      },
      buttonsWrapperRoot: {
        margin: [margins.md, 0, 0],
        zIndex: 1,
      },
      headerSportsbookCarousel: {
        fontSize: 35,
        lineHeight: 0.9,
        letterSpacing: 'normal',
        fontWeight: '900',
        color: white,
        fontFamily: fonts.agora,
        marginRight: margins.md,
        display: 'inline-block',
      },
      descriptionSportsbookCarousel: {
        fontSize: 20,
        fontWeight: '900',
        color: white,
        padding: '0',
        lineHeight: 1.1,
        display: 'inline-block',
      },
      bannerContentSportsbookCarousel: {
        width: 'auto',
        padding: margins.md,
        position: 'relative',
        // '&:before': {
        //   content: "''",
        //   width: 'calc(100% - 20px)',
        //   margin: -margins.xm,
        //   position: 'absolute',
        //   height: '100%',
        //   background: primary,
        //   zIndex: 1,
        // },
        // '&:after': {
        //   content: "''",
        //   top: 0,
        //   right: -50,
        //   position: 'absolute',
        //   width: 0,
        //   height: 0,
        //   borderStyle: 'solid',
        //   borderWidth: '222px 0 0 70px',
        //   borderColor: `transparent transparent transparent ${primary}`,
        //   /*  width: 145,
        //   top: -40,
        //   right: -55,
        //   position: 'absolute',
        //   height: 400,
        //   background: primary,
        //   // z-index: 1;
        //   transform: 'rotate(-20deg)', */
        // },
      },
    },
    BannerItemCasinoCarousel: {
      heightFix: {
        height: 300,
      },
      contentWrapper: {
        height: ' 100%',
        '&:before': {
          content: `''`,
          position: 'absolute',
          width: '40%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%)`,
        },
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        alignItems: 'flex-start',
        minHeight: 100,
      },
      header: {
        textShadow: `none`,
      },
      description: {
        textShadow: `none`,
        textAlign: 'left',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: background2,
        borderBottom: `1px solid ${background1}`,
      },
      descriptionTimeWrapper: {
        color: white,
      },
    },
  };
};
