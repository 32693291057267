// @flow
import { type JSSThemeDefaultBetgeniusType } from '../JSSThemeDefaultBetgenius';

export const JSSThemeBetgenius61100: JSSThemeDefaultBetgeniusType = themeVars => {
  const { margins } = themeVars;
  return {
    iframeWrapper: {
      margin: [margins.md, 0, 0],
    },
  };
};
