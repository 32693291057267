// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses61100 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: { background2, primary, white, background5 },
      colorCombinations: { whiteAccent, bg4Bg1 },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        background: primary,
        color: white,
        '&:hover': {
          background: background5,
          color: white,
        },
        '&.active': {
          background: background5,
          color: white,
        },
      },
      tabsItemMobile: {
        ...whiteAccent,
        borderRight: 'none',
        '&.active': bg4Bg1,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      bonusContainer: {
        border: 'none',
        background: 'transparent',
        padding: 0,
      },
    },
    Bonuses: {
      root: {
        border: 'none',
        background: background2,
      },
    },
  };
};
