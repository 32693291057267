// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61100,
  JSSThemeVariables61100,
  getJSSThemePatterns61100,
} from './JSSTheme61100';
import { GeoblockPage61100 } from './GeoblockPage61100';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop61100').then(module => module.FullDesktop61100),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile61100').then(module => module.FullMobile61100),
  }),

  JSSTheme: JSSTheme61100,
  JSSThemeVariables: JSSThemeVariables61100,
  JSSThemePatterns: getJSSThemePatterns61100,
  GeoblockPage: GeoblockPage61100,
});
